import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const questions = [
  { question: 'Which one of us sat next to football legend Sergio Aguero on a flight to Buenos Aires, Argentina?', answers: ['Oliver', 'Alec'], correct: 'Oliver' },
  { question: 'Which one of us has had all four of their wisdom teeth taken out?', answers: ['Oliver', 'Alec'], correct: 'Alec' },
  { question: 'Which one of us has fed a lion on a night out?', answers: ['Oliver', 'Alec'], correct: 'Oliver' },
  { question: 'Which one of us volunteers for Yorkshire Ambulance Service?', answers: ['Oliver', 'Alec'], correct: 'Oliver' },
  { question: 'Which one of us remains forever undefeated at the egg and spoon race?', answers: ['Oliver', 'Alec'], correct: 'Alec' },
  { question: 'Which one of us won a tea making competition in China AND was awarded ‘chop stick champion’?', answers: ['Oliver', 'Alec'], correct: 'Alec' },
  { question: 'Which of us bought our grandmother a handcrafted scarf in Poland only for it to actually be… a tablecloth?', answers: ['Oliver', 'Alec'], correct: 'Alec' },
  { question: 'Which of us was excluded from school for hacking into the school IT system?', answers: ['Oliver', 'Alec'], correct: 'Oliver' },
  { question: 'Who put a garden pitchfork through their foot as a child?', answers: ['Oliver', 'Alec'], correct: 'Oliver' },
  { question: 'Which of us was late for school once after their pet cockatiel escaped?', answers: ['Oliver', 'Alec'], correct: 'Alec' }
]

function WQuiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(JSON.parse(localStorage.getItem('wcompleted')) || false);
    const [score, setScore] = useState(parseInt(localStorage.getItem('wscore'), 10) || 0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [completed, setCompleted] = useState(JSON.parse(localStorage.getItem('wcompleted')) || false);

    useEffect(() => {
      const savedScore = localStorage.getItem('wscore');
      if (savedScore) {
        setScore(parseInt(savedScore, 10));
      }

      const savedCompleted = localStorage.getItem('wcompleted');
      if (savedCompleted) {
        setCompleted(JSON.parse(savedCompleted));
      }
    }, []);

    useEffect(() => {
      localStorage.setItem('wscore', score);
    }, [score]);

   

    const handleAnswerOptionClick = (answer) => {
        if (currentQuestion === 0) {
            resetScore();
          }
        setSelectedAnswer(answer);
        setIsCorrect(answer === questions[currentQuestion].correct);
      };
      
     

      useEffect(() => {
        if (isCorrect) {
          setScore((score) => score + 1);
        }
      }, [isCorrect]);

      const resetScore = () => {
        setScore(0);
        localStorage.removeItem('wscore');
      }
      
    
      


    const handleNextQuestion = () => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true);
        setCompleted(true);
        localStorage.setItem('wcompleted', true);
      }

      setSelectedAnswer(null);
      setIsCorrect(null);
    };

    return (
        <div className="container-fluid"  >
             
        <div className="container">
        <div className="row pt-20 pb-20">
        <Link to="/quizzes" className='btn btn-link text-decoration-none' style={{ display: 'flex', alignItems: 'center' }}>
  <i className="fa fa-arrow-left mr-5" aria-hidden="true"></i><span>All quizzes</span>
</Link>
          {completed ? (
           <div>
           <p>Your score: {score}</p>
           {score !== questions.length &&
             <button className="btn btn-primary" onClick={() => {
               setCompleted(false);
               setShowScore(false);
               setScore(0);
               setCurrentQuestion(0);
               localStorage.removeItem('wcompleted');
               localStorage.removeItem('wscore');
             }}>Try Again</button>
           }

{score === questions.length &&
              <button className="btn btn-primary" onClick={() => {
                setCompleted(false);
                setShowScore(false);
                setScore(0);
                setCurrentQuestion(0);
                localStorage.removeItem('wcompleted');
                localStorage.removeItem('wscore');
              }}>Reset your score</button>
            }
         </div>
          ) : (
            <>
              <div className='mt-20'>
                {showScore ? (
                  <div className='score-section'>
                    <p>You scored {score} out of {questions.length}!</p>
                   
                  </div>
                ) : (
                  <>
                    <div className="card">
            
                      <div className="card-header">
                        <div className='question-count'>
                          <span>Question {currentQuestion + 1}</span>/{questions.length}
                        </div>
                        <div className='question-text'>{questions[currentQuestion].question}</div>
                      </div>
                      <div className='answer-section'>
                      <ul className="list-group list-group-flush">
  {questions[currentQuestion].answers.map((answer, index) => (
    <li className="list-group-item"
      onClick={() => {
        // If it's first question - reset the score in the local storage
        if (currentQuestion === 0) {
          setScore(0);
          localStorage.removeItem('wscore');
        }
        if (!selectedAnswer) { // Only allow click if no answer has been selected
          handleAnswerOptionClick(answer);
        }
      }}
      key={index}
      style={{ backgroundColor: selectedAnswer === answer ? (isCorrect ? 'green' : 'red') : 'initial'}}
    >
      {answer}
    </li>
  ))}
</ul>
                      </div>
                    </div>
                  </>
                )}
                {selectedAnswer && (
                  <button className='btn btn-primary mt-20' href='#' onClick={handleNextQuestion}>Next Question</button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default WQuiz;
