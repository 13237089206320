import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// stylesheets
import './css/bootstrap.min.css';
import './css/ratherton.css';
import './css/fontawesome/css/all.css';
import Logo from "./logo.svg";

// components

import Header from './components/header';
import Hero from './components/hero';
import NowPlaying from './components/playlist';
import BarContent from './components/barcontent';
import GeoQuiz from './components/quizzes/geo';
import WhoQuiz from './components/quizzes/who';
import PhotoQuiz from './components/quizzes/quiz1';
import HomeContent from './components/homecontent';
import QuizContent from './components/quizcontent';
import Photos from './components/photos';
import Gallery from './components/gallery';

function App() {
  return (

    <Router>
    <main className="flex-shrink-0 terrace-bottom">
      <Header />
    <Routes>

    <Route path="/" element={
  <>
   <Hero logo={Logo} title="" subtitle="" />
  <HomeContent logo={Logo} />
  
</>
        } />

<Route path="/foto" element={
  <>
   <Hero logo={Logo} title="" subtitle="" />
   <Gallery />
  <HomeContent logo={Logo} />
  
</>
        } />

<Route path="/bar" element={
  <>
  <Hero logo={Logo} title="The bar" subtitle="" />
  <BarContent />
</>
        } />


<Route path="/quizzes" element={
  <>
  <Hero logo={Logo} title="Quizzes" subtitle="" />
  <QuizContent />

</>
        } />

<Route path="/quizzes/geography" element={
  <>
  <Hero logo={Logo} title="The country quiz" subtitle="Test your Oliver and Alec geography knowledge. " />
  <GeoQuiz />

</>
        } />

<Route path="/quizzes/whodunnit" element={
  <>
  <Hero logo={Logo} title="Whodunnit quiz" subtitle="Can you guess who done it?" />
  <WhoQuiz />

</>
        } />

<Route path="/quizzes/whoisit" element={
  <>
  <Hero logo={Logo} title="Film and music" subtitle="We’ve subtly some famous film posters, can you guess which films they’re from, and which artist made the album?" />
  <PhotoQuiz />

</>
        } />







<Route path="/photos" element={
  <>
  <Hero logo={Logo} title="Photos" subtitle="" />
  <Photos />
  
</>
        } />

<Route path="/music" element={
  <>
   <Hero logo={Logo} title="Now playing" subtitle="" />
  <div className="container-fluid">
  <div className="container">
  <div className="row pt-20 pb-20">
    <NowPlaying />
    </div>
    </div>
    </div>
  
</>
        } />



       </Routes>
   
</main>
      </Router>

  );
}

export default App;
