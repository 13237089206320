import React from "react";

const Hero = (props) => {

    return (  
        <>
        <div className="container-fluid hero-terrace"  >
    <div className="container">
       
       
    <div className="row pt-20 pb-20 align-items-center justify-content-center">
    <div className="col-2 p-0">
      <img className="home-logo img-fluid svg-class" src={props.logo} alt="Logo" />
    </div>
    <div className="col-10 d-flex flex-column justify-content-center">
      <h1 className="h2 p-0 m-0">{props.title}</h1>
      <p className="lead text-muted mb-0">{props.subtitle}</p>
    </div>
  </div>



    </div>
    </div>
   
    </>
    );     
  }

  


export default Hero;