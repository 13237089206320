import React, {  } from 'react';
import { Link } from 'react-router-dom';


const Home = ({logo}) => {

  
  //  const [loaded, setLoaded] = useState(false);


    return (  
        <>
        <div className="container-fluid"  >
    <div className="container">

    <div className="row pt-20">
      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center mb-30 text-center">
   {/*   <img className="home-logo pb-20 w-50 fade-in img-fluid mx-auto d-block svg-class" src={logo} alt="Logo" onLoad={() => setLoaded(true)} /> */}
        <h2>The wedding of</h2>
        <p className="font-special home-welcome pb-20 pt-20">Alec & Oliver</p>
  <p>Grab a <Link to='/bar'>drink</Link>, test your knowledge with our <Link to='/quizzes'>quizzes</Link> and enjoy the <Link to='/music'>music</Link>. There's also a photo guest book to sign!</p>
  <p>Thank you for coming, it means the world to us both that you're here - don't forget to take a wedding favour when you leave!</p>
      </div>
     
     
    </div>
{/* 
         <div className="row pt-30 pb-40">
     <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Timings</h2>
        <div className="line"></div>
        <p className="lead">5pm</p>
        <p className="text-muted text-small">Arrival</p>
        <p className="lead">6pm</p>
        <p className="text-muted text-small">Let's eat!</p>
        <p className="lead">7pm</p>
        <p className="text-muted text-small">Bust a move!</p>
        <p className="lead">11.30pm</p>
        <p className="text-muted text-small">Last song</p>
      </div>
    </div>

 */}
    </div>
    </div>
    </>
    );     
  }

  


export default Home;