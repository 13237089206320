import React, {  } from 'react';

const Photos = () => {


  return (
    <div className="container-fluid"  >
    <div className="container">

    <div className="row pt-40">
      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center mb-30 text-center">
        <h2>Photos</h2>
        <p className="lead text-muted">...</p>
        <hr></hr>
      </div>
     
     
    </div>
    </div>
    </div>
  );
}

export default Photos;