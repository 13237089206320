import { Link } from 'react-router-dom';
import React from 'react';


const Header = (props) => {



    return (

        <>
 <nav className="navbar fixed-bottom navbar-expand-lg navbar-terrace">
 <div className="container">
 <div className='terrace-nav-bar d-flex flex-row flex-nowrap overflow-auto'>

  <div className="col text-center">
           <Link to="/" >
        <i className="fa-solid fa-house fa-2x"></i>
        <p>Home</p>
     </Link>
    </div>

    <div className="col text-center">
           <Link to="/bar" >
        <i className="fa-solid fa-martini-glass-citrus fa-2x"></i>
        <p>The bar</p>
     </Link>
    </div>

    <div className="col text-center">
           <Link to="/quizzes" >
        <i className="fa-solid fa-circle-question fa-2x"></i>
        <p>Quizzes</p>
     </Link>
    </div>

    <div className="col text-center">
           <Link to="/music" >
        <i className="fa-solid fa-music fa-2x"></i>
        <p>Now playing</p>
     </Link>
    </div>




</div>

   
 

 </div>
 <div className="checkout">

</div>
</nav>



</>
);
};

export default Header;
