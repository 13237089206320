import React, {  } from 'react';

const Playlist = () => {


  return (
   
   
    <div className="container-fluid">
<div className="">
  <div className="row pt-30 pb-40">
<>
  <iframe title="spotify player" src="https://open.spotify.com/embed/playlist/4ruPesUV8O8Os83T63zmZs?theme=0" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</>




  </div>
</div>
</div>



 
  );
}

export default Playlist;



