import React from "react";

const Menu = (props) => {

    return (  
        <>
        <div className="container-fluid"  >
    <div className="container">

    <div className="row pt-40">
      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center mb-30 text-center">
        <h2>Bar Menu</h2>
        <p className="lead text-muted"></p>
        <hr></hr>
      </div>
     
     
    </div>

         <div className="row pt-30 pb-40">
    
   
     
     <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Wine</h2>
        <div className="line"></div>
        <h3 className="text-decoration-underline">White</h3>
        <p><span className="lead">Pinot Grigio</span> <span className="text-muted text-small">£5.20/£22.50</span></p>
        <p><span className="lead">Sauvignon Blanc</span> <span className="text-muted text-small">£5.20/£22.50</span></p>
        <p><span className="lead">Chardonnay</span> <span className="text-muted text-small">£5.20</span></p>
        <h3 className="text-decoration-underline">Rose</h3>
        <p><span className="lead">Zinfandel</span> <span className="text-muted text-small">£5.20</span></p>
        <h3 className="text-decoration-underline">Red</h3>
        <p><span className="lead">Merlot</span> <span className="text-muted text-small">£5.20/£22.50</span></p>
        <p><span className="lead">Shiraz</span> <span className="text-muted text-small">£5.20/£22.50</span></p>
        <p><span className="lead">Cabernet Sauvignon</span> <span className="text-muted text-small">£5.20</span></p>
        <h3 className="text-decoration-underline">Sparkling</h3>
        <p><span className="lead">Prosecco</span> <span className="text-muted text-small">£6.80/£27.50</span></p>
        <p><span className="lead">Aperol Spritz</span> <span className="text-muted text-small">£6.80</span></p>
      </div>

      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Beers and Ciders</h2>
        <div className="line"></div>
        <h3 className="text-decoration-underline">Draft</h3>
        <p><span className="lead">Vocation Heart and Soul (Session IPA)</span> <span className="text-muted text-small">£4.50 (pint)</span></p>
        <p><span className="lead">Vocation Hebden Lager</span> <span className="text-muted text-small">£4.50 (pint)</span></p>
        <h3 className="text-decoration-underline">Cans/Bottles</h3>
        <p><span className="lead">Vocation Bread and Butter (Modern ale)</span> <span className="text-muted text-small">£4.50 (440ml)</span></p>
        <p><span className="lead">Vocation Life and Death (Classic IPA)</span> <span className="text-muted text-small">£4.50 (440ml)</span></p>
        <p><span className="lead">Vocation Death by Cherries (Fruit sour)</span> <span className="text-muted text-small">£4.75 (440ml)</span></p>
        <p><span className="lead">Vocation Naughty and Nice (Chocolate stout)</span> <span className="text-muted text-small">£4.75 (440ml)</span></p>
        <p><span className="lead">Aspall Cyder</span> <span className="text-muted text-small">£4.50</span></p>
        <p><span className="lead">Guinness</span> <span className="text-muted text-small">£4.50</span></p>
        <p><span className="lead">Gordons Gin and Tonic</span> <span className="text-muted text-small">£4.75</span></p>
        <p><span className="lead">Peroni</span> <span className="text-muted text-small">£4.25</span></p>
      </div>

      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Spirits</h2>
        <div className="line"></div>
        <h3 className="text-decoration-underline">Gin</h3>
        <p><span className="lead">Whitely Neil Rhubarb Gin</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Tanqueray Gin</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Bombay Sapphire</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <h3 className="text-decoration-underline">Whiskey and rum</h3>
        <p><span className="lead">Jack Daniels</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Havana Club White</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Havana Club Dark Rum</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Kraken Spiced Rum</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Bacardi</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Famous Grouse</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <h3 className="text-decoration-underline">Other</h3>
        <p><span className="lead">Cointreau</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>

        <p><span className="lead">Three Barrels Brandy</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
       
        <p><span className="lead">Smirnoff Vodka</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Sierra Tequila</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
        <p><span className="lead">Martini</span> <span className="text-muted text-small">£3.75 (25ml)</span></p>
      
     
      
    
        <p><span className="lead">Aperol</span> <span className="text-muted text-small">£3.75 (50ml)</span></p>
        <p><span className="lead">Apple Sourz</span> <span className="text-muted text-small">£3.75 (50ml)</span></p>

      </div>

      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Alcohol Free</h2>
        <div className="line"></div>
        <p><span className="lead">Lyres Alcohol-free</span> <span className="text-muted text-small">£3.00</span></p>
        <p><span className="lead">Seedlip Alcohol-free</span> <span className="text-muted text-small">£3.00</span></p>
        <p><span className="lead">Lucky Saint (0.5%)</span> <span className="text-muted text-small">£3.50</span></p>
        <p><span className="lead">Erdinger Alkoholfrei (0%)</span> <span className="text-muted text-small">£4.00</span></p>
        <p><span className="lead">Peroni (0%)</span> <span className="text-muted text-small">£3.50</span></p>
        <p><span className="lead">Guinness (0%)</span> <span className="text-muted text-small">£4.50</span></p>

      </div>

      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center text-center mb-30">
        <h2>Soft drinks / mixers</h2>
        <div className="line"></div>
        <p><span className="lead">Heartsease Sparkling Drinks </span> <span className="text-muted text-small">£2.25</span></p>
        <p><span className="lead">Coke / Diet Coke</span> <span className="text-muted text-small">£1.85</span></p>
        <p><span className="lead">Tonic / Slimline / Lemonade / Soda</span> <span className="text-muted text-small">£1.60</span></p>
      
      </div>



    </div>


    </div>
    </div>
    </>
    );     
  }

  


export default Menu;