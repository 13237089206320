

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const questions = [
  { question: '1.png', answers: ['Barbie', 'Bonnie and Clyde', 'Grease', 'Sheilas Wheels'], correct: 'Barbie' },
  { question: '2.png', answers: ['War of the Worlds', 'Oppenheimer', 'The Dark Knight', '2012'], correct: 'Oppenheimer' },
  { question: '3.png', answers: ['The Godfather', 'Dog Day Afternoon', 'Serpico', 'Scarface'], correct: 'Scarface' },
  { question: '4.png', answers: ['Lord of the Rings', 'Pirates of the Caribbean', 'Braveheart', 'The Last Samurai'], correct: 'Lord of the Rings' },
  { question: '5.png', answers: ['Jaws', 'Poseidon', 'Titanic', 'Cruising with Jane McDonald'], correct: 'Titanic' },
  { question: '6.png', answers: ['The Smiths', 'The Arctic Monkeys', 'The Verve', 'The Stone Roses'], correct: 'The Arctic Monkeys' },
  { question: '7.png', answers: ['Foo Fighters', 'Nickelback', 'The Killers', 'Nirvana'], correct: 'Nirvana' },
  { question: '8.png', answers: ['Adele', 'Celine Dion', 'Aretha Franklin', 'Diana Ross'], correct: 'Adele' },
  { question: '9.png', answers: ['Cher', 'Kylie', 'Beyonce', 'Rihanna'], correct: 'Beyonce' },
  { question: '10.png', answers: ['David Bowie', 'Lady Gaga', 'Madonna', 'Björk'], correct: 'Lady Gaga' }
]

function Quiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(JSON.parse(localStorage.getItem('pcompleted')) || false);
    const [score, setScore] = useState(parseInt(localStorage.getItem('pscore'), 10) || 0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [completed, setCompleted] = useState(JSON.parse(localStorage.getItem('pcompleted')) || false);

    useEffect(() => {
      const savedScore = localStorage.getItem('pscore');
      if (savedScore) {
        setScore(parseInt(savedScore, 10));
      }

      const savedCompleted = localStorage.getItem('pcompleted');
      if (savedCompleted) {
        setCompleted(JSON.parse(savedCompleted));
      }
    }, []);

    useEffect(() => {
      localStorage.setItem('pscore', score);
    }, [score]);

    const handleAnswerOptionClick = (answer) => {
        if (currentQuestion === 0) {
            resetScore();
          }
        setSelectedAnswer(answer);
        setIsCorrect(answer === questions[currentQuestion].correct);
      };
      
      useEffect(() => {
        if (isCorrect) {
          setScore((score) => score + 1);
        }
      }, [isCorrect]);

      const resetScore = () => {
        setScore(0);
        localStorage.removeItem('pscore');
      }

    const handleNextQuestion = () => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true);
        setCompleted(true);
        localStorage.setItem('pcompleted', true);
      }

      setSelectedAnswer(null);
      setIsCorrect(null);
    };

    return (
        <div className="container-fluid"  >
             
        <div className="container">
        <div className="row pt-20 pb-20">
        <Link to="/quizzes" className='btn btn-link text-decoration-none' style={{ display: 'flex', alignItems: 'center' }}>
  <i className="fa fa-arrow-left mr-5" aria-hidden="true"></i><span>All quizzes</span>
</Link>
          {completed ? (
            <div>
            <p>Your score: {score}</p>
            {score !== questions.length &&
              <button className="btn btn-primary" onClick={() => {
                setCompleted(false);
                setShowScore(false);
                setScore(0);
                setCurrentQuestion(0);
                localStorage.removeItem('pcompleted');
                localStorage.removeItem('pscore');
              }}>Try Again</button>
            }

{score === questions.length &&
              <button className="btn btn-primary" onClick={() => {
                setCompleted(false);
                setShowScore(false);
                setScore(0);
                setCurrentQuestion(0);
                localStorage.removeItem('pcompleted');
                localStorage.removeItem('pscore');
              }}>Reset your score</button>
            }
          </div>
          ) : (
            <>
              <div className='mt-20'>
                {showScore ? (
                  <div className='score-section'>
                    <p>You scored {score} out of {questions.length}!</p>
                   
                  </div>
                ) : (
                  <>
                    <div className="card">
                      <div className="card-header">
                        <div className='question-count'>
                          <span>Question {currentQuestion + 1}</span>/{questions.length}
                        </div>
                        <img className="img-fluid rounded" src={require(`./quiz1/${questions[currentQuestion].question}`)} alt="quiz"/>
                      </div>
                      <div className='answer-section'>
                      <ul className="list-group list-group-flush">
  {questions[currentQuestion].answers.map((answer, index) => (
    <li className="list-group-item"
      onClick={() => {
        // If it's first question - reset the score in the local storage
        if (currentQuestion === 0) {
          setScore(0);
          localStorage.removeItem('gscore');
        }
        if (!selectedAnswer) { // Only allow click if no answer has been selected
          handleAnswerOptionClick(answer);
        }
      }}
      key={index}
      style={{ backgroundColor: selectedAnswer === answer ? (isCorrect ? 'green' : 'red') : 'initial'}}
    >
      {answer}
    </li>
  ))}
</ul>
                      </div>
                    </div>
                  </>
                )}
                {selectedAnswer && (
                  <button className='btn btn-primary mt-20' href='#' onClick={handleNextQuestion}>Next Question</button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Quiz;
