import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Quiz = ({logo}) => {


    const [geoscore] = useState(parseInt(localStorage.getItem('gscore'), 10) || 0);
    const [whoscore] = useState(parseInt(localStorage.getItem('wscore'), 10) || 0);
    const [picscore] = useState(parseInt(localStorage.getItem('pscore'), 10) || 0);
    const totalscore = geoscore + whoscore + picscore;
    const criteria = 30;


    return (  
        <>
        <div className="container-fluid"  >
    <div className="container">


    <div className="row pt-40">
      <div className="col-sm-12 col-xs-12 d-flex flex-column align-self-center mb-0 text-center">
        <h2>So you think you know Alec and Oliver?</h2>
        <p className="lead text-muted">Test your knowledge with these quizzes - the first 20 people to score top marks across ALL quizzes will win a drinks voucher.</p>
        <hr></hr>
      </div>
     
     
    </div>

         <div className="row pt-20 pb-20" >
        
 
         {totalscore >= criteria && (
  <div className="card bg-success text-white mt-20 px-0">
    <div className="card-body">
      <h5 className="card-title text-white">Congratulations!</h5>
      <p className="card-text text-white">You did it, you've got top marks! A drinks token is available to the first 20 people, so be quick and check with Oliver or Alec!</p>
    </div>
  </div>
)}

         <Link className='text-decoration-none mt-20 px-0' to="/quizzes/geography" >
         <div className="card ">
  <div className="card-body">
    <h5 className="card-title">The Country Quiz</h5>
    <p className="card-text">Test your Oliver and Alec geography knowledge. </p>
    <p>Score: {geoscore}</p>
  </div>
</div>
</Link>


<Link className='text-decoration-none mt-20 px-0' to="/quizzes/whodunnit" >
         <div className="card ">
  <div className="card-body">
    <h5 className="card-title">Who Dunnit?</h5>
    <p className="card-text">Can you guess who done it?</p>
    <p>Score: {whoscore}</p>
  </div>
</div>
</Link>


<Link className='text-decoration-none mt-20 px-0' to="/quizzes/whoisit" >
         <div className="card ">
  <div className="card-body">
    <h5 className="card-title">Film and Music</h5>
    <p className="card-text">We’ve subtly some famous film posters, can you guess which films they’re from, and which artist made the album?</p>
    <p>Score: {picscore}</p>
  </div>
</div>
</Link>




    
    </div>


    </div>
    </div>
    </>
    );     
  }

  


export default Quiz;