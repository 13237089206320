import React from 'react';

const photos = [
  { src: '/img/1.jpg', alt: 'Photo 1' },
  { src: 'path/to/photo2.jpg', alt: 'Photo 2' },
  { src: 'path/to/photo3.jpg', alt: 'Photo 3' },
  // Add more photos as needed
];

function PhotoGallery() {
  return (
    <div className="container mt-4">
      <div className="row">
        {photos.map((photo, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card">
              <img src={photo.src} className="card-img-top" alt={photo.alt} />
              <div className="card-body">
                <p className="card-text">{photo.alt}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoGallery;
